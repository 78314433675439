import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText, PrismicText } from "@prismicio/react"

import Layout from "../../components/layout/Layout"
import Button from "../../components/Button"

const FunnelPage = ({ data }) => {
  const doc = data.allPrismicFunnelPage.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <Layout
      smoothScrolling={false}
      title={doc.node.data.meta_title}
      description={doc.node.data.meta_description}
      image={doc.node.data.hero_image.url}
      showNaviButtons={false}
    >
      <div>
        <div className="mt-10 mx-auto max-w-4xl px-4 sm:mt-12 sm:px-6 md:mt-16">
          <section id="header" className="text-center">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
              <PrismicText field={doc.node.data.title.richText} />
            </h1>
            <div className="my-6 flex justify-center">
              <img
                className="h-10 w-10 rounded-full"
                src={doc.node.data.author_image.url}
                alt=""
              />
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  {doc.node.data.meta_author.text}
                </p>
                <div className="flex text-sm leading-5 text-gray-600">
                  <time>{doc.node.data.date}</time>
                  <span className="mx-1">&middot;</span>
                  <span>6 min read</span>
                </div>
              </div>
            </div>
            <div className="my-6">
              <img
                className="md:h-96 w-full object-cover"
                src={doc.node.data.hero_image.url}
                alt=""
              />
            </div>
          </section>
          <section id="post-pody">
            <PrismicRichText field={doc.node.data.post_body.richText} />
          </section>
          <section>
            <div className="text-center mt-16 mb-16">
              <Button
                className="text-lg"
                link={doc.node.data.cta_link.text}
                buttonid="cta-button"
              >
                {doc.node.data.cta_button_title.text}
              </Button>
            </div>
          </section>
          <section id="post-pody-2">
            <PrismicRichText field={doc.node.data.post_body_2.richText} />
          </section>
          <section>
            <div className="text-center mt-16 mb-16">
              <Button
                className="text-lg"
                link={doc.node.data.cta_link.text}
                buttonid="cta-button"
              >
                {doc.node.data.cta_button_title.text}
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FunnelPageQuery($uid: String) {
    allPrismicFunnelPage(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            title {
              richText
            }
            date
            post_body {
              richText
            }
            post_body_2 {
              richText
            }
            hero_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            author_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            cta_button_title {
              text
            }
            cta_link {
              text
            }
            meta_title
            meta_description
            meta_author {
              text
            }
          }
        }
      }
    }
  }
`
export default FunnelPage
